<template>
    <a-modal
        v-model="feedVisable"
        centered
        :width="800"
        :footer="null"
        class="addDialog"
        @cancel="close"
        :destroyOnClose="true"
        :closable="false"
        :confirm-loading="confirmLoading"
    >
        <div class="dialog_header">
            <h2>卡片反馈</h2>
            <div class="close"><svg-icon icon-class="icon_close" @click.native="close"></svg-icon></div>
        </div>
        <div class="dialog_content">
            <a-form-model
                ref="ruleForm"
            >
                <a-form-model-item>
                    <div class="form-item">
                        <div class="label">卡片名称：</div>
                        <div class="input">{{info.Title}}</div>
                    </div>
                </a-form-model-item>
                 <a-form-model-item>
                    <div class="form-item">
                        <div class="label">选中文字：</div>
                        <div class="input">{{info.Txt}}</div>
                    </div>
                </a-form-model-item>
                <a-form-model-item>
                    <div class="form-item">
                        <div class="label">选中文字：</div>
                        <div class="input">
                            <a-textarea
                                read-only
                                v-model="info.Note"
                                :auto-size="{ minRows: 5, maxRows: 5 }"
                            />
                        </div>
                    </div>
                 </a-form-model-item>
            </a-form-model>
        </div>
    </a-modal>
</template>
<script>
export default {
    props: {
        dialogVisable: {
            type: Boolean,
            default: false,
        },
        info:{
            type:Object,
            default:() => {
                return{
                    Title:'',
                    Note:'',
                    Txt:''
                }
            }
        },
        selection:String,
        modelId:[String,Number]
    },
    data() {
        return {
            confirmLoading:false,
            feedVisable:false,
            value:'',
            form:{
                value:'',
            },
        }
    },
    watch:{
        dialogVisable:{
            handler(newVal,oldVal){
                this.feedVisable = newVal
            },
            immediate:true
        }
    },
    methods:{
        close() {
            this.$emit('close')
        },
        confirmDownload(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.confirmLoading = true
                    const form = {
                        Title:this.info.label || this.info.Name,
                        modelId:this.$route.name == 'card-info' ?  this.$route.query.modelId || 0 : this.$route.params.id,
                        CardId:this.info.card?.Id || this.info.Id,
                        Txt:this.selection,
                        Note:this.form.value
                    }
                    this.AddFeedback(Base64.encode(JSON.stringify(form))).then(res => {
                        console.log(res);
                        this.$message.success('添加成功')
                        this.form.value = ''
                        this.confirmLoading =  false
                        this.$emit('success')
                    }).catch(err => {
                        console.log(err);
                        this.confirmLoading =  false
                    })
                    
                    
                } else {
                    return false;
                }
            });
        },
        cancelDownload(){
            this.$emit('close')
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal-body{
    padding-left: 50px;
    padding-right: 50px;
}
.form-item{
    display: flex;
    font-size: 18px;
    color: rgb(85, 85, 85);
    line-height: 1.667;
    margin-bottom: 10px;
    .label{
        width: 170px;
        flex-shrink: 0;
    }
    .input{
        flex: 1;
    }
}
.ant-input{
    width: 100%;
    margin-top: 5px;
    font-size: 18px;
    color: rgb(85, 85, 85);
    border: 1px solid rgb(229, 229, 229);
    border-radius: 10px;
}
.imgs{
    /deep/img{
        margin-bottom: 5px;
        object-fit: cover;
        cursor: zoom-in;
        margin-right: 5px;
    }
}
</style>