<template>
    <div class="pagenation">
        <a-pagination :defaultCurrent="1" :pageSize="pageSize" :total="total" @change="pageChange" :size="size"
            :current="current" />
    </div>
</template>
<script>
export default {
    // props: ['pageSize', 'total', 'size', 'current'],
    props: {
        pageSize: Number,
        total: Number,
        size: Number,
        current: {
            type: Number,
            default: 1
        }
    },
    methods: {
        pageChange(page, pageSize) {
            this.$emit('pageChange', { page, pageSize })
        }
    }
}
</script>
<style lang="less"></style>