<template>
    <div class="model">
        <a-spin :spinning="spinning">
            <table class="table">
                <colgroup>
                    <col width='320'>
                    <col width='580'>
                    <col width='140'>
                </colgroup>
                <thead class="table-thead">
                    <tr>
                        <th class="th1">卡片名称</th>
                        <th>选中的内容</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <tr v-for="item  in data" :key="item.Id">
                        <td>
                            <div>{{item.Title}}</div>
                        </td>
                        <td class="center">
                            {{item.Txt}}
                        </td>
                        <td class="handle">
                            <div class="handle_inner">
                                <span @click="open(item)">详情</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <a-empty v-if="data.length == 0"/>
        </a-spin>
        <Pagination
            :pageSize="params.pageSize"
            :total="total"
            @pageChange="pageChange"
            v-if="data.length>0"
        />
        <CardDialog :dialogVisable="visable" :info="dialogData" @close="close"/>
    </div>
</template>
<script>
import Pagination from '~/components/common/Pagination'
import CardDialog from '~/components/Personal/Feedback/CardDialog'
import { GetMyCardFeedbackList } from '@/api/user'
export default {
    metaInfo() {
		return {
			title: '卡片反馈-个人中心-文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '卡片反馈-个人中心-文物出版社'
                },
                {
                    name: 'description',
					content: '卡片反馈-个人中心-文物出版社'
                }
            ]
		}
	},
    components:{
        Pagination,
        CardDialog
    },
    data(){
        return{
            data:[],
            params:{
                pageIndex:1,
                pageSize:10
            },
            total:0,
            spinning:false,
            visable:false,
            dialogData:{},
        }
    },
    methods:{
        pageChange(){
            this.params.pageIndex = page.page
            this.getList()
            document.getElementsByClassName('personal')[0].scrollIntoView({behavior: "auto"})
        },
        async getList(){
            this.spinning = true
            const { data , total } = await GetMyCardFeedbackList(this.params)
            this.data = data 
            this.total = total
            this.spinning = false
        },
        close(){
            this.visable = false
        },
        open(item){
            this.dialogData = item
            this.visable = true
        }
    },
    mounted(){
        this.getList()
    }
}
</script>
<style lang="less" scoped>
:deep(.ant-spin-nested-loading){
    padding: 0 40px;
}
.model{
    .table{
        border-top: 0;
    }
}
.th1{
    text-align: left !important;
    padding-left: 30px;
}
.center{
    text-align: center;
}
</style>